@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700;800&display=swap');




// $primary-color : #42bed8;
// $link-color: #2f3053;;

// $theme-colors: (
//     "primary": $primary-color,
//     "success": #28a745,
//     "danger": #dc3545,
// );

// $modal-border-width: 0px;

body{
    margin: 0px;
    padding: 0px;
}





// // General
// $body-bg: #fbfbfb;
// $body-color: #212529;
// $border-color: #e8edeb;
// $border-radius: 5px;

// /// Card 
// $card-border-width: 0px;
// /// Buttons
// $btn-padding-y: 0.28rem;
// $btn-border-width: .15rem;
// $btn-font-weight: 500;

// @import 'bootstrap/scss/bootstrap.scss';
// body{
//     font-family: 'Noto Sans', sans-serif;
// }
// main{
//     width: 100vw;
//     height: 100vh;
//     display: flex;
// }
// .page-header{
//     margin: -1rem;
//     margin-bottom: 0.3rem;
//     padding: 1rem;
//     .title{
//         font-size: 1.5rem;  
//     }
//     .subtitle{
//         font-size: .8rem;
//     }
//     .dropdown-toggle{
//         border: none !important;
//     }
//     .user-menu{


//     }
// }

// page{
//     flex: 1;
//     padding: 1rem;
//     max-height: calc(100vh);
//     overflow-y: scroll;
// }

// .btn.btn-primary{
//     color: #fff !important;
// }

// .dashboard-sidebar{
//     width: 15rem;
//     background: #fff;
//     box-shadow: 1px 1px 1px 1px #f2f2f2;
//     display: flex;
//     flex-direction: column;
//     position: relative;
//     .logo{
//         width: 100%;
//         border-bottom: 1px solid #f2f2f2;
//         img{
//             width: 100%;
//             padding: 1rem;
//             height: 5rem;
//         }
//     }
    
//     .nav{
//         padding: .5rem;
//     }
//     .divider{
//         text-transform: uppercase;
//         font-size: 0.8rem;
//         margin: 0.5rem 0rem;
//         font-weight: 500;
//         color: rgb(158 158 158)
//     }
//     .nav-link{
//         font-weight: 500;
//         display: flex;
//         align-items: center;
//         padding: 0.5rem .1rem !important;   
//         margin: 0px;
//         i{
//             // width: 2rem;
//             // height: 2rem;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             margin-right: .5rem;
//         }
//         &.active{
//             font-weight: 500;
//             border-radius: 3px;
//             background: #fff;
//         }
//         &.action{
//             text-align: center;
//             border: 2px solid $primary-color;
//             padding: .1rem .5rem !important;
//             width: fit-content;
//             border-radius: 30px;
//             color: $primary-color;
//             justify-content: center;
//             border: 2px solid #42bed8;
//             margin-left: -0.3rem;
//             i{
//                 height: inherit;
//             }
//         }
//     }

//     .service-select{
//         position: absolute;
//         right: 0px;
//         left: 0px;
//         bottom: 0px;
//         padding: .5rem;

//     }
// }

// // Table
// .rdt_Table {
//     border: 1px solid $border-color;
//     border-radius: $border-radius;
//     overflow: hidden;
// }
// .rdt_TableCol {
//     font-weight: 700;
//     font-size: .9rem;
//     text-transform: uppercase;
// }

// .rdt_TableCell {
//     font-weight: 500;
//     font-size: .8rem;
// }

// .stepper{
//     position: relative;
//     .stepper-item{
//         background: transparent;
//         display: flex;
//         align-items: center;
//         border: none;
//         z-index: 999;
//         .icon{
//             background: #ddd;
//             width: 3rem;
//             height: 3rem;
//             text-align: center;
//             border-radius: 50px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-weight: 500;
//             margin-right: 1rem;

//             i{
//                 font-size: 1.5rem;
//             }
//         }
//         .title{
//             font-weight: 500;
//         }

//         &[data-isDone] .icon{
//             background: $primary-color;
//             color: #fff;
//         }
//         & + .stepper-item{
//             margin-top: 2rem;
//         }
//     }
//     &:after{
//         content: " ";
//         display: block;
//         background: #ddd;
//         position: absolute;
//         top: 1rem;
//         bottom: 1rem;
//         width: 3px;
//         left: 2.4rem;
//         height: 90%;
//         z-index: 888;
//     }
// }

// .card.stats-box {
//     border: none;
//     background: transparent;
//     .value{
//         font-weight: 700;
//         font-size: 1.5rem;
//         margin-bottom: 0px; 
//     }
//     .title{
//         margin-bottom: 0px;
//         font-weight: 500;
//         font-size: .9rem;
//         color: #4c4c4c;
//     }
// }
// .card.filled{
//     background: #f9f9f9;
// }

// .cnic-camera{
//     flex-Direction: column;
//     border-radius: 10px;
//     border: 1px solid #ddd;
//     width: 100%;
//     max-width: 25rem;
//     overflow: hidden;
//     position: relative;
//     video, img{
//         flex: 1;
//         width: 100%;
//         height: 15rem;
//         background: #000;
//         object-fit: inherit;
//     }
//     video{margin-bottom: -0.35rem;}
//     .placeholder{
//         cursor: inherit;
//         position: absolute;
//         top: 3%;
//         left: 5%;
//         right: 5%;
//         background-color: inherit;
//         img{
//             background: transparent !important;
//             height: 11rem;
//         }
//     }
//     img.captured{
//         position: absolute;
//         top: 0px;
//         bottom: 0px;
//         left: 0px;
//     }
//     .controls{
//         position: absolute;
//         bottom: 0px;
//         left: 0px;
//         right: 0px;
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         padding: 0.5rem;
//         button{
//             border: 3px solid transparent;
//             color: #fff;
//             font-size: 1rem;
//         }
//         button:nth-child(1){
//             background: $primary-color !important;
//             border-color: #fff;
//         }
//     }
// }

// .photo-camera{
//     position: relative;
//     border-radius: 10px;
//     position: relative;
//     width: 15rem;
//     height: 12rem;
//     overflow: hidden;
//     img, video{
//         width: 100%;
//         height: 100%;
//         background: #000;
//         object-fit: inherit;
//         // border-radius: 50%;
//         // border: 1px solid $border-color;
//     }
//     img.captured{
//         text-align: center;
//         position: absolute;
//         // bottom: 0px;
//         left: 0px;
//         right: 0px;
        
//     }
//     .capture-btn{
//         background: #42bed8;
//         color: #fff;
//         width: 2.5rem;
//         height: 2.5rem;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         border-radius: 50%;
//         font-size: 1rem;
//         border: 3px solid #fff;
//     }
// }

// .verification-view{
//     flex: 1;
//     padding: 1rem;
//     .label{
//         font-weight: 600;
//     }
//     .table{
//         td,th{
//             border: none;
//         }
//     }
//     img{
//         border-radius: 10px;
//         border: 1px solid #ddd;
//     }
//     img.cnic{
//         width: 100%;
//         height: 100%;
//     }
//     .user-image{
//         width: 13rem;
//         height: 13rem;
//         margin-right: 1rem;
//     }

//     .top-detail{
//         td{
//             padding-top: .1rem;
//             padding-bottom: .1rem;
//         }
//     }
// }
// .avatar{
//     border-radius: 50%;
//     border: 2px solid $border-color;
// }


// @media print {
//     body * {
//         visibility: hidden;
//     }
//     [data-print=true] * {
//         visibility: visible;
//     }
//     [data-print=true]{
//         position: absolute;
//         top: 0;
//         left: 0;
//     }
//     .verification-view{
//         td, th{
//             padding-top: .4rem;
//             padding-bottom: .4rem;
//         }
//         .user-image{
//             width: 100%;
//             height: 15rem;
//             // width: 200px;
//             // height: 200px;
//         }
//     }

// }

.ant-btn{
    display: flex;
    align-items: center;
}

.camera-capture{
    border: 2px solid #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    max-width: 40em;
    .ant-card-body{
        padding: 0px;
    }

    .ant-card-body{
        // height: 20em;
        position: relative;
        overflow: hidden;
    }
    .footer{
        background: #f3f3f3;
        padding: .5em;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    video{
        width: 100%;
        object-fit: contain;
        height: 22em;
        z-index: 1;
        background: #000;
    }
    .captured{
        width: 100%;
        height: 22em;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: contain;
        background: #000;
    }

    .camera-status{
        // width: 1em;
        // height: 1em;
        border-radius: 50%;
        position: absolute;
        top: 1em;
        left: 1em;
        width: 1em;
        height: 1em;
        &[data-is-flat=false]{
            background: red;
        }
        &[data-is-flat=true] {
            background: green;
        }
    }
    .error{
        border: none;
        border-radius: 0px;
        background: #ff4d4f;
        color: #fff;
        z-index: 3;
        padding: .5em;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
    }

    // button{
    //     border: 1px solid #212529 !important;
    //     padding: 0.1em 0.5em;
    //     // border: none;
    //     // // background: transparent;
    //     // i.fa-camera {
    //     //     font-size: 2em;
    //     // }
    //     &:disabled{
    //         opacity: .1;
    //     }
    // }

}