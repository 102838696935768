:root {
  --window-offset: 8%;
  --roundness: 6px;
}

.ant-card {
  border-radius: var(--roundness);
}

/*** Modal ***/
.ant-modal-content {
  border-radius: var(--roundness) !important;
}

/*** Form ***/
.ant-input {
  border-radius: var(--roundness);
}
.ant-input-group-addon {
  border-radius: var(--roundness) !important;
}
.ant-select-selector {
  border-radius: var(--roundness) !important;
}
.ant-select-dropdown {
  border-radius: var(--roundness) !important;
}

/*** Button ***/
.ant-btn {
  font-weight: 500;
  border-radius: var(--roundness);
}

/*** Table ***/
.ant-table-thead .ant-table-cell {
  background: #fff !important;
  border-radius: var(--roundness) !important;
}

.ant-form-item-label label,
.ant-divider-inner-text,
.ant-menu-title-content {
  font-weight: 500;
}

.ant-space {
  width: 100%;
}

.webhooks-form .headers .ant-space-item:nth-child(1),
.webhooks-form .headers .ant-space-item:nth-child(2) {
  flex: 1;
}

.ipwhitelist-form .ant-space {
  align-items: start;
}
.ipwhitelist-form .ant-space .ant-space-item:nth-child(1) {
  flex: 1;
}

.login-page {
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal-confirm-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-typography mark {
  color: #1677ff;
  background: transparent;
}

header .ant-menu-light {
  border-color: transparent;
}

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-block: 2em;
  text-align: center;
}

.feature-item img {
  width: 8em;
}
